import * as React from 'react';
import Page from '../components/Page/page';
import { News } from './llama-software/news';
import { graphql, useStaticQuery } from "gatsby";
import '../css/base.scss'
switch (process.env.PRODUCT) {
    case 'survival':
        import('../css/llama-survival/overrides.scss');
        break;
    case 'hns':
        import('../css/hide-and-seek/overrides.scss');
        break;
    case 'software':
    default:
        import('../css/llama-software/overrides.scss');
        break;
}

const IndexPage = ({ location }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          background {
            url
            type
          }
          title
          maxNews
          subtitle
          rootStyles
        }
      }
    }
   `);

    const { title, subtitle, rootStyles, maxNews, background = {
        type: 'image',
        url: '/images/central-hangar.jpg'
    } } = data?.site?.siteMetadata;


    return (
        <>
            <style>{rootStyles}</style>
            <Page background={background} styles={rootStyles} location={location} animateHeader seoTitle="Home" title={title} subtitle={subtitle}>
                <News limit={maxNews} />
            </Page>
        </>
    );
}

export default IndexPage
